<template>
    <div class="all">
        <img class="lt" src="@/assets/border/left-top.png" alt="">
        <img class="rt" src="@/assets/border/right-top.png" alt="">
        <img class="lb" src="@/assets/border/left-bottom.png" alt="">
        <img class="rb" src="@/assets/border/right-bottom.png" alt="">
    </div>
</template>

<script>
export default {}
</script>

<style scoped>
.lt {
    position: absolute;
    left: 0;
    top: 0;
}

.rt {
    position: absolute;
    right: 0;
    top: 0;
}

.lb {
    position: absolute;
    left: 0;
    bottom: 0;
}

.rb {
    position: absolute;
    right: 0;
    bottom: 0;
}
</style>
