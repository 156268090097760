<template>
  <div class="card">
    <div class="title">中国主要港口进出口货柜总量</div>
    <div  ref="mainData" :style="{width: '100%', height: '305px'}"/>
    <border-line></border-line>
  </div>
</template>
<script>
import * as echarts from 'echarts'
import BorderLine from "@/components/BorderLine/BorderLine.vue";
export default {
  name: 'CommonCircular',
  components: {BorderLine},
  data(){
    return {
      aData: [],
      dataAll:[],
      total:'',
      currentIndex: -1
    }
  },
  mounted() {
    this.init()
  },
  created() {
    this.getData()
  },
  methods: {
    async getData() {
      const data = {}
      let res = await this.api.getPortOutInListData(data)
      this.total = res.data.F_TalCount
      this.aData = res.data
      this.dataAll = res.data.PortOutInEntityList
      setInterval(()=>{
        this.init()
      },3000)
    },
    init() {
      const myChart = echarts.init(this.$refs.mainData)
      const option = {
        legend: {
          orient: 'vertical',
          right: 10,
          top: 'middle',
          data:this.aData.PortOutInEntityList?.map(item=>{
            return {
              name:item.F_PortName,
              value:item.F_TowerCount,
            }
          })
          // orient: 'vertical',
          // x: 'right',
        },
        title: [{
          x: '50%',
          y: '45%',
          textAlign: 'center',
          textStyle: {
            fontSize: '18',
            fontWeight: '600',
            color: 'rgba(0,0,0,0.90)',
            textAlign: 'center',
          },
        },{
          // text:this.total,
          // left: 'center',
          // top: 'center',
          // subtext: '货柜/柜',
        }],
        series: [
          {
            stillShowZeroSum:false,
            type: 'pie',
            radius: ['50%', '70%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center',
              formatter: '{b} ({d}%)' // 设置标签显示格式，显示类别的名称和百分比
            },
            labelLine: {
              show: false
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '18',
                fontWeight: 'bold'
              }
            },
            data:this.aData.PortOutInEntityList?.map(item=>{
              return {
                name:item.F_PortName,
                value:item.F_TowerCount
              }
            })
          }
        ]
      };
      option && myChart.setOption(option);
      // 取消之前高亮的图形
      myChart.dispatchAction({
        type: 'downplay',
        seriesIndex: 0,
        dataIndex: this.currentIndex
      })
      this.currentIndex = (this.currentIndex + 1) % this.aData.PortOutInEntityList.length
      // 高亮当前图形
      myChart.dispatchAction({
        type: 'highlight',
        seriesIndex: 0,
        dataIndex: this.currentIndex
      })
    }
  }
}
</script>

<style scoped>
.chart{
  padding: 24px;
}
.card {
  width: 568px;
  height: 341px;
  position: relative;
}
.title {
  width: 100%;
  padding: 10px 0;
  background: linear-gradient(90deg, rgba(5, 63, 152, 0) 0%, #053F98 47%, rgba(5, 63, 152, 0) 100%);
  font-size: 16px;
  color: #00CAF0;
}
</style>
