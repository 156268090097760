// 每个国家点位
export const Site =
    {
        "天津": [117.4219, 39.4189],
        "北京": [116.4551, 40.2539],
        "上海": [121.4648, 31.2891],
        "重庆": [107.7539, 30.1904],
        "六安": [116.3123, 31.8329],
        "安庆": [116.7517, 30.5255],
        "滁州": [118.1909, 32.536],
        "宣城": [118.8062, 30.6244],
        "阜阳": [115.7629, 32.9919],
        "宿州": [117.5208, 33.6841],
        "黄山": [118.0481, 29.9542],
        "巢湖": [117.7734, 31.4978],
        "亳州": [116.1914, 33.4698],
        "池州": [117.3889, 30.2014],
        "合肥": [117.29, 32.0581],
        "蚌埠": [117.4109, 33.1073],
        "芜湖": [118.3557, 31.0858],
        "淮北": [116.6968, 33.6896],
        "淮南": [116.7847, 32.7722],
        "马鞍山": [118.6304, 31.5363],
        "铜陵": [117.9382, 30.9375],
        "澳门": [113.5715, 22.1583],
        "酉阳": [108.8196, 28.8666],
        "奉节": [109.3909, 30.9265],
        "巫溪": [109.3359, 31.4813],
        "开县": [108.4131, 31.2561],
        "彭水": [108.2043, 29.3994],
        "云阳": [108.8306, 31.0089],
        "城口": [108.7756, 31.9098],
        "江津": [106.2158, 28.9874],
        "石柱": [108.2813, 30.1025],
        "巫山": [109.8853, 31.1188],
        "丰都": [107.8418, 29.9048],
        "武隆": [107.655, 29.35],
        "秀山": [109.0173, 28.5205],
        "黔江": [108.7207, 29.4708],
        "綦江": [106.6553, 28.8171],
        "忠县": [107.8967, 30.3223],
        "梁平": [107.7429, 30.6519],
        "潼南": [105.7764, 30.1135],
        "垫江": [107.4573, 30.2454],
        "大足": [105.7544, 29.6136],
        "铜梁": [106.0291, 29.8059],
        "荣昌": [105.5127, 29.4708],
        "璧山": [106.2048, 29.5807],
        "南平": [118.136, 27.2845],
        "三明": [117.5317, 26.3013],
        "龙岩": [116.8066, 25.2026],
        "宁德": [119.6521, 26.9824],
        "福州": [119.4543, 25.9222],
        "漳州": [117.5757, 24.3732],
        "泉州": [118.3228, 25.1147],
        "莆田": [119.0918, 25.3455],
        "厦门": [118.1689, 24.6478],
        "酒泉": [96.2622, 40.4517],
        "张掖": [99.7998, 38.7433],
        "甘南": [102.9199, 34.6893],
        "武威": [103.0188, 38.1061],
        "陇南": [105.304, 33.5632],
        "庆阳": [107.5342, 36.2],
        "白银": [104.8645, 36.5076],
        "定西": [104.5569, 35.0848],
        "天水": [105.6445, 34.6289],
        "兰州": [103.5901, 36.3043],
        "平凉": [107.0728, 35.321],
        "临夏": [103.2715, 35.5737],
        "金昌": [102.074, 38.5126],
        "嘉峪关": [98.1738, 39.8035],
        "清远": [112.9175, 24.3292],
        "韶关": [113.7964, 24.7028],
        "湛江": [110.3577, 20.9894],
        "梅州": [116.1255, 24.1534],
        "河源": [114.917, 23.9722],
        "肇庆": [112.1265, 23.5822],
        "惠州": [114.6204, 23.1647],
        "茂名": [111.0059, 22.0221],
        "江门": [112.6318, 22.1484],
        "阳江": [111.8298, 22.0715],
        "云浮": [111.7859, 22.8516],
        "广州": [113.5107, 23.2196],
        "汕尾": [115.5762, 23.0438],
        "揭阳": [116.1255, 23.313],
        "珠海": [113.7305, 22.1155],
        "佛山": [112.8955, 23.1097],
        "潮州": [116.7847, 23.8293],
        "汕头": [117.1692, 23.3405],
        "深圳": [114.5435, 22.5439],
        "东莞": [113.8953, 22.901],
        "中山": [113.4229, 22.478],
        "百色": [106.6003, 23.9227],
        "河池": [107.8638, 24.5819],
        "桂林": [110.5554, 25.318],
        "南宁": [108.479, 23.1152],
        "柳州": [109.3799, 24.9774],
        "崇左": [107.3364, 22.4725],
        "来宾": [109.7095, 23.8403],
        "玉林": [110.2148, 22.3792],
        "梧州": [110.9949, 23.5052],
        "贺州": [111.3135, 24.4006],
        "钦州": [109.0283, 22.0935],
        "贵港": [109.9402, 23.3459],
        "防城港": [108.0505, 21.9287],
        "北海": [109.314, 21.6211],
        "遵义": [106.908, 28.1744],
        "黔东南": [108.4241, 26.4166],
        "毕节": [105.1611, 27.0648],
        "黔南": [107.2485, 25.8398],
        "铜仁": [108.6218, 28.0096],
        "黔西南": [105.5347, 25.3949],
        "六盘水": [104.7546, 26.0925],
        "安顺": [105.9082, 25.9882],
        "贵阳": [106.6992, 26.7682],
        "儋州": [109.3291, 19.5653],
        "文昌": [110.8905, 19.7823],
        "乐东": [109.0283, 18.6301],
        "三亚": [109.3716, 18.3698],
        "琼中": [109.8413, 19.0736],
        "东方": [108.8498, 19.0414],
        "海口": [110.3893, 19.8516],
        "万宁": [110.3137, 18.8388],
        "澄迈": [109.9937, 19.7314],
        "白沙": [109.3703, 19.211],
        "琼海": [110.4208, 19.224],
        "昌江": [109.0407, 19.2137],
        "临高": [109.6957, 19.8063],
        "陵水": [109.9924, 18.5415],
        "屯昌": [110.0377, 19.362],
        "定安": [110.3384, 19.4698],
        "保亭": [109.6284, 18.6108],
        "五指山": [109.5282, 18.8299],
        "黑河": [127.1448, 49.2957],
        "大兴安岭": [124.1016, 52.2345],
        "哈尔滨": [127.9688, 45.368],
        "齐齐哈尔": [124.541, 47.5818],
        "牡丹江": [129.7815, 44.7089],
        "绥化": [126.7163, 46.8018],
        "伊春": [129.1992, 47.9608],
        "佳木斯": [133.0005, 47.5763],
        "鸡西": [132.7917, 45.7361],
        "双鸭山": [133.5938, 46.7523],
        "大庆": [124.7717, 46.4282],
        "鹤岗": [130.4407, 47.7081],
        "七台河": [131.2756, 45.9558],
        "承德": [117.5757, 41.4075],
        "张家口": [115.1477, 40.8527],
        "保定": [115.0488, 39.0948],
        "唐山": [118.4766, 39.6826],
        "沧州": [116.8286, 38.2104],
        "石家庄": [114.4995, 38.1006],
        "邢台": [114.8071, 37.2821],
        "邯郸": [114.4775, 36.535],
        "秦皇岛": [119.2126, 40.0232],
        "衡水": [115.8838, 37.7161],
        "廊坊": [116.521, 39.0509],
        "南阳": [112.4011, 33.0359],
        "信阳": [114.8291, 32.0197],
        "洛阳": [112.0605, 34.3158],
        "驻马店": [114.1589, 32.9041],
        "周口": [114.873, 33.6951],
        "商丘": [115.741, 34.2828],
        "三门峡": [110.8301, 34.3158],
        "新乡": [114.2029, 35.3595],
        "平顶山": [112.9724, 33.739],
        "郑州": [113.4668, 34.6234],
        "安阳": [114.5325, 36.0022],
        "开封": [114.5764, 34.6124],
        "焦作": [112.8406, 35.1508],
        "许昌": [113.6975, 34.0466],
        "濮阳": [115.1917, 35.799],
        "漯河": [113.8733, 33.6951],
        "鹤壁": [114.3787, 35.744],
        "恩施": [109.5007, 30.2563],
        "十堰": [110.5115, 32.3877],
        "宜昌": [111.1707, 30.7617],
        "襄樊": [111.9397, 31.9263],
        "黄冈": [115.2686, 30.6628],
        "荆州": [113.291, 30.0092],
        "荆门": [112.6758, 30.9979],
        "咸宁": [114.2578, 29.6631],
        "随州": [113.4338, 31.8768],
        "孝感": [113.9502, 31.1188],
        "武汉": [114.3896, 30.6628],
        "黄石": [115.0159, 29.9213],
        "神农架": [110.4565, 31.5802],
        "天门": [113.0273, 30.6409],
        "仙桃": [113.3789, 30.3003],
        "潜江": [112.7637, 30.3607],
        "鄂州": [114.7302, 30.4102],
        "怀化": [109.9512, 27.4438],
        "永州": [111.709, 25.752],
        "邵阳": [110.9619, 26.8121],
        "郴州": [113.2361, 25.8673],
        "常德": [111.4014, 29.2676],
        "湘西": [109.7864, 28.6743],
        "衡阳": [112.4121, 26.7902],
        "岳阳": [113.2361, 29.1357],
        "益阳": [111.731, 28.3832],
        "长沙": [113.0823, 28.2568],
        "株洲": [113.5327, 27.0319],
        "张家界": [110.5115, 29.328],
        "娄底": [111.6431, 27.7185],
        "湘潭": [112.5439, 27.7075],
        "盐城": [120.2234, 33.5577],
        "徐州": [117.5208, 34.3268],
        "南通": [121.1023, 32.1625],
        "淮安": [118.927, 33.4039],
        "苏州": [120.6519, 31.3989],
        "宿迁": [118.5535, 33.7775],
        "连云港": [119.1248, 34.552],
        "扬州": [119.4653, 32.8162],
        "南京": [118.8062, 31.9208],
        "泰州": [120.0586, 32.5525],
        "无锡": [120.3442, 31.5527],
        "常州": [119.4543, 31.5582],
        "镇江": [119.4763, 31.9702],
        "赣州": [115.2795, 25.8124],
        "吉安": [114.884, 26.9659],
        "上饶": [117.8613, 28.7292],
        "九江": [115.4224, 29.3774],
        "抚州": [116.4441, 27.4933],
        "宜春": [115.0159, 28.3228],
        "南昌": [116.0046, 28.6633],
        "景德镇": [117.334, 29.3225],
        "萍乡": [113.9282, 27.4823],
        "鹰潭": [117.0813, 28.2349],
        "新余": [114.95, 27.8174],
        "延边": [129.397, 43.2587],
        "吉林": [126.8372, 43.6047],
        "白城": [123.0029, 45.2637],
        "松原": [124.0906, 44.7198],
        "长春": [125.8154, 44.2584],
        "白山": [127.2217, 42.0941],
        "通化": [125.9583, 41.8579],
        "四平": [124.541, 43.4894],
        "辽源": [125.343, 42.7643],
        "大连": [122.2229, 39.4409],
        "朝阳": [120.0696, 41.4899],
        "丹东": [124.541, 40.4242],
        "铁岭": [124.2773, 42.7423],
        "沈阳": [123.1238, 42.1216],
        "抚顺": [124.585, 41.8579],
        "葫芦岛": [120.1575, 40.578],
        "阜新": [122.0032, 42.2699],
        "锦州": [121.6626, 41.4294],
        "鞍山": [123.0798, 40.6055],
        "本溪": [124.1455, 41.1987],
        "营口": [122.4316, 40.4297],
        "辽阳": [123.4094, 41.1383],
        "盘锦": [121.9482, 41.0449],
        "呼伦贝尔": [120.8057, 50.2185],
        "阿拉善": [102.019, 40.1001],
        "锡林郭勒": [115.6421, 44.176],
        "鄂尔多斯": [108.9734, 39.2487],
        "赤峰": [118.6743, 43.2642],
        "巴彦淖尔": [107.5562, 41.3196],
        "通辽": [121.4758, 43.9673],
        "乌兰察布": [112.5769, 41.77],
        "兴安": [121.3879, 46.1426],
        "包头": [110.3467, 41.4899],
        "呼和浩特": [111.4124, 40.4901],
        "乌海": [106.886, 39.4739],
        "吴忠": [106.853, 37.3755],
        "中卫": [105.4028, 36.9525],
        "固原": [106.1389, 35.9363],
        "银川": [106.3586, 38.1775],
        "石嘴山": [106.4795, 39.0015],
        "海西": [94.9768, 37.1118],
        "玉树": [93.5925, 33.9368],
        "果洛": [99.3823, 34.0466],
        "海南": [100.3711, 35.9418],
        "海北": [100.3711, 37.9138],
        "黄南": [101.5686, 35.1178],
        "海东": [102.3706, 36.2988],
        "西宁": [101.4038, 36.8207],
        "烟台": [120.7397, 37.5128],
        "临沂": [118.3118, 35.2936],
        "潍坊": [119.0918, 36.524],
        "青岛": [120.4651, 36.3373],
        "菏泽": [115.6201, 35.2057],
        "济宁": [116.8286, 35.3375],
        "德州": [116.6858, 37.2107],
        "滨州": [117.8174, 37.4963],
        "聊城": [115.9167, 36.4032],
        "东营": [118.7073, 37.5513],
        "济南": [117.1582, 36.8701],
        "泰安": [117.0264, 36.0516],
        "威海": [121.9482, 37.1393],
        "日照": [119.2786, 35.5023],
        "淄博": [118.0371, 36.6064],
        "枣庄": [117.323, 34.8926],
        "莱芜": [117.6526, 36.2714],
        "榆林": [109.8743, 38.205],
        "延安": [109.1052, 36.4252],
        "汉中": [106.886, 33.0139],
        "安康": [109.1162, 32.7722],
        "商洛": [109.8083, 33.761],
        "宝鸡": [107.1826, 34.3433],
        "渭南": [109.7864, 35.0299],
        "咸阳": [108.4131, 34.8706],
        "西安": [109.1162, 34.2004],
        "铜川": [109.0393, 35.1947],
        "忻州": [112.4561, 38.8971],
        "吕梁": [111.3574, 37.7325],
        "临汾": [111.4783, 36.1615],
        "晋中": [112.7747, 37.37],
        "运城": [111.1487, 35.2002],
        "大同": [113.7854, 39.8035],
        "长治": [112.8625, 36.4746],
        "朔州": [113.0713, 39.6991],
        "晋城": [112.7856, 35.6342],
        "太原": [112.3352, 37.9413],
        "阳泉": [113.4778, 38.0951],
        "甘孜": [99.9207, 31.0803],
        "阿坝": [102.4805, 32.4536],
        "凉山": [101.9641, 27.6746],
        "绵阳": [104.7327, 31.8713],
        "达州": [107.6111, 31.333],
        "广元": [105.6885, 32.2284],
        "雅安": [102.6672, 29.8938],
        "宜宾": [104.6558, 28.548],
        "乐山": [103.5791, 29.1742],
        "南充": [106.2048, 31.1517],
        "巴中": [107.0618, 31.9977],
        "泸州": [105.4578, 28.493],
        "成都": [103.9526, 30.7617],
        "资阳": [104.9744, 30.1575],
        "攀枝花": [101.6895, 26.7133],
        "眉山": [103.8098, 30.0146],
        "广安": [106.6333, 30.4376],
        "德阳": [104.48, 31.1133],
        "内江": [104.8535, 29.6136],
        "遂宁": [105.5347, 30.6683],
        "自贡": [104.6667, 29.2786],
        "台湾": [121.0295, 23.6082],
        "香港": [114.2784, 22.3057],
        "巴音郭楞": [88.1653, 39.6002],
        "和田": [81.167, 36.9855],
        "哈密": [93.7793, 42.9236],
        "阿克苏": [82.9797, 41.0229],
        "阿勒泰": [88.2971, 47.0929],
        "喀什": [77.168, 37.8534],
        "塔城": [86.6272, 45.8514],
        "昌吉": [89.6814, 44.4507],
        "克孜勒苏": [74.6301, 39.5233],
        "吐鲁番": [89.6375, 42.4127],
        "伊犁": [82.5513, 43.5498],
        "博尔塔拉": [81.8481, 44.6979],
        "乌鲁木齐": [87.9236, 43.5883],
        "克拉玛依": [85.2869, 45.5054],
        "阿拉尔": [81.2769, 40.6549],
        "图木舒克": [79.1345, 39.8749],
        "五家渠": [87.5391, 44.3024],
        "石河子": [86.0229, 44.2914],
        "那曲": [88.1982, 33.3215],
        "阿里": [82.3645, 32.7667],
        "日喀则": [86.2427, 29.5093],
        "林芝": [95.4602, 29.1138],
        "昌都": [97.0203, 30.7068],
        "山南": [92.2083, 28.3392],
        "拉萨": [91.1865, 30.1465],
        "普洱": [100.7446, 23.4229],
        "红河": [103.0408, 23.6041],
        "文山": [104.8865, 23.5712],
        "曲靖市": [103.9417, 25.7025],
        "楚雄": [101.6016, 25.3619],
        "大理": [99.9536, 25.6805],
        "临沧": [99.613, 24.0546],
        "迪庆": [99.4592, 27.9327],
        "昭通": [104.0955, 27.6031],
        "昆明": [102.9199, 25.4663],
        "丽江": [100.448, 26.955],
        "西双版纳": [100.8984, 21.8628],
        "保山": [99.0637, 24.9884],
        "玉溪": [101.9312, 23.8898],
        "怒江": [99.1516, 26.5594],
        "德宏": [98.1299, 24.5874],
        "丽水": [119.5642, 28.1854],
        "杭州": [119.5313, 29.8773],
        "温州": [120.498, 27.8119],
        "宁波": [121.5967, 29.6466],
        "舟山": [122.2559, 30.2234],
        "台州": [121.1353, 28.6688],
        "金华": [120.0037, 29.1028],
        "衢州": [118.6853, 28.8666],
        "绍兴": [120.564, 29.7565],
        "嘉兴": [120.9155, 30.6354],
        "湖州": [119.8608, 30.7782],
        "招远": [120.38, 37.35],
        "乳山": [121.52, 36.89],
        "莱西": [120.53, 36.86],
        "胶南": [119.97, 35.88],
        "诸暨": [120.23, 29.71],
        "富阳": [119.95, 30.07],
        "临安": [119.72, 30.23],
        "库尔勒": [86.06, 41.68],
        "溧阳": [119.48, 31.43],
        "句容": [119.16, 31.95],
        "金坛": [119.56, 31.74],
        "宜兴": [119.82, 31.36],
        "胶州": [120.03336, 36.264622],
        "平度": [119.97, 36.77],
        "江阴": [120.26, 31.91],
        "蓬莱": [120.75, 37.8],
        "文登": [122.05, 37.2],
        "瓦房店": [121.979603, 39.627114],
        "寿光": [118.73, 36.86],
        "慈溪": [30.18, 121.27],
        "庄河市": [122.970612, 39.69829],
        "东港市": [124.149437, 39.883467],
        "德惠市": [125.703327, 44.533909],
        "延吉市": [129.51579, 42.906964],
        "尚志市": [127.968539, 45.214953],
        "金坛区": [119.573395, 31.744399],
        "启东市": [121.659724, 31.810158],
        "海门市": [121.176609, 31.893528],
        "大丰区": [120.470324, 33.199531],
        "扬中市": [119.828054, 32.237266],
        "新源县": [83.258493, 43.434249],
        "扶绥县": [107.911533, 22.635821],
        "磁县": [114.38208, 36.367673],
        "阳城县": [112.422014, 35.482177],
        "南岗区": [126.652098, 45.755971],
        "海安县": [120.465995, 32.540289],
        "东海县": [118.766489, 34.522859],
        "灌云县": [119.255741, 34.298436],
        "淮阴区": [119.020817, 33.622452],
        "盱眙县": [118.493823, 33.00439],
        "金湖县": [119.016936, 33.018162],
        "建湖县": [119.793105, 33.472621],
        "射阳县": [120.257444, 33.773779],
        "嘉善县": [120.921871, 30.841352],
        "仙居县": [120.735074, 28.849213],
        "肥西县": [117.166118, 31.719646],
        "章贡区": [114.93872, 25.851367],
        "临朐县": [118.539876, 36.516371],
        "汶上县": [116.487146, 35.721746],
        "西平县": [114.026864, 33.382315],
        "云梦县": [113.750616, 31.021691],
        "衡东县": [112.950412, 27.083531],
        "九龙坡区": [106.480989, 29.523492],
        "景泰县": [104.066394, 37.193519],
        "自流井区": [104.778188, 29.343231],
        "盐亭县": [105.391991, 31.22318],
        "凉州区": [102.634492, 37.93025],
        "青羊区": [104.055731, 30.667648],
        "泰兴区": [120.020228, 32.168784],
        "海宁市": [120.688821, 30.525544],
        "长乐市": [119.510849, 25.960583],
        "肥城市": [116.763703, 36.1856],
        "偃师市": [112.787739, 34.723042],
        "林州市": [113.823767, 36.063403],
        "四会市": [112.695028, 23.340324],
        "阿富汗": [
            67.709953,
            33.93911
        ],
        "安哥拉": [
            17.873887,
            -11.202692
        ],
        "阿尔巴尼亚": [
            20.168331,
            41.153332
        ],
        "阿联酋": [
            53.847818,
            23.424076
        ],
        "阿根廷": [
            -63.61667199999999,
            -38.416097
        ],
        "亚美尼亚": [
            45.038189,
            40.069099
        ],
        "法属南半球和南极领地": [
            69.348557,
            -49.280366
        ],
        "澳大利亚": [
            133.775136,
            -25.274398
        ],
        "奥地利": [
            14.550072,
            47.516231
        ],
        "阿塞拜疆": [
            47.576927,
            40.143105
        ],
        "布隆迪": [
            29.918886,
            -3.373056
        ],
        "比利时": [
            4.469936,
            50.503887
        ],
        "贝宁": [
            2.315834,
            9.30769
        ],
        "布基纳法索": [
            -1.561593,
            12.238333
        ],
        "孟加拉国": [
            90.356331,
            23.684994
        ],
        "保加利亚": [
            25.48583,
            42.733883
        ],
        "巴哈马": [
            -77.39627999999999,
            25.03428
        ],
        "波斯尼亚和黑塞哥维那": [
            17.679076,
            43.915886
        ],
        "白俄罗斯": [
            27.953389,
            53.709807
        ],
        "伯利兹": [
            -88.49765,
            17.189877
        ],
        "百慕大": [
            -64.7505,
            32.3078
        ],
        "玻利维亚": [
            -63.58865299999999,
            -16.290154
        ],
        "巴西": [
            -51.92528,
            -14.235004
        ],
        "文莱": [
            114.727669,
            4.535277
        ],
        "不丹": [
            90.433601,
            27.514162
        ],
        "博茨瓦纳": [
            24.684866,
            -22.328474
        ],
        "中非共和国": [
            20.939444,
            6.611110999999999
        ],
        "加拿大": [
            -106.346771,
            56.130366
        ],
        "瑞士": [
            8.227511999999999,
            46.818188
        ],
        "智利": [
            -71.542969,
            -35.675147
        ],
        "中国": [
            104.195397,
            35.86166
        ],
        "象牙海岸": [
            -5.547079999999999,
            7.539988999999999
        ],
        "喀麦隆": [
            12.354722,
            7.369721999999999
        ],
        "刚果民主共和国": [
            21.758664,
            -4.038333
        ],
        "刚果共和国": [
            15.827659,
            -0.228021
        ],
        "哥伦比亚": [
            -74.297333,
            4.570868
        ],
        "哥斯达黎加": [
            -83.753428,
            9.748916999999999
        ],
        "古巴": [
            -77.781167,
            21.521757
        ],
        "北塞浦路斯": [
            33.429859,
            35.126413
        ],
        "塞浦路斯": [
            33.429859,
            35.126413
        ],
        "捷克共和国": [
            15.472962,
            49.81749199999999
        ],
        "德国": [
            10.451526,
            51.165691
        ],
        "吉布提": [
            42.590275,
            11.825138
        ],
        "丹麦": [
            9.501785,
            56.26392
        ],
        "多明尼加共和国": [
            -70.162651,
            18.735693
        ],
        "阿尔及利亚": [
            1.659626,
            28.033886
        ],
        "厄瓜多尔": [
            -78.18340599999999,
            -1.831239
        ],
        "埃及": [
            30.802498,
            26.820553
        ],
        "厄立特里亚": [
            39.782334,
            15.179384
        ],
        "西班牙": [
            -3.74922,
            40.46366700000001
        ],
        "爱沙尼亚": [
            25.013607,
            58.595272
        ],
        "埃塞俄比亚": [
            40.489673,
            9.145000000000001
        ],
        "芬兰": [
            25.748151,
            61.92410999999999
        ],
        "斐": [
            178.065032,
            -17.713371
        ],
        "福克兰群岛": [
            -59.523613,
            -51.796253
        ],
        "法国": [
            2.213749,
            46.227638
        ],
        "加蓬": [
            11.609444,
            -0.803689
        ],
        "英国": [
            -3.435973,
            55.378051
        ],
        "格鲁吉亚": [
            -82.9000751,
            32.1656221
        ],
        "加纳": [
            -1.023194,
            7.946527
        ],
        "几内亚": [
            -9.696645,
            9.945587
        ],
        "冈比亚": [
            -15.310139,
            13.443182
        ],
        "几内亚比绍": [
            -15.180413,
            11.803749
        ],
        "赤道几内亚": [
            10.267895,
            1.650801
        ],
        "希腊": [
            21.824312,
            39.074208
        ],
        "格陵兰": [
            -42.604303,
            71.706936
        ],
        "危地马拉": [
            -90.23075899999999,
            15.783471
        ],
        "法属圭亚那": [
            -53.125782,
            3.933889
        ],
        "圭亚那": [
            -58.93018,
            4.860416
        ],
        "洪都拉斯": [
            -86.241905,
            15.199999
        ],
        "克罗地亚": [
            15.2,
            45.1
        ],
        "海地": [
            -72.285215,
            18.971187
        ],
        "匈牙利": [
            19.503304,
            47.162494
        ],
        "印尼": [
            113.921327,
            -0.789275
        ],
        "印度": [
            78.96288,
            20.593684
        ],
        "爱尔兰": [
            -8.24389,
            53.41291
        ],
        "伊朗": [
            53.688046,
            32.427908
        ],
        "伊拉克": [
            43.679291,
            33.223191
        ],
        "冰岛": [
            -19.020835,
            64.963051
        ],
        "以色列": [
            34.851612,
            31.046051
        ],
        "意大利": [
            12.56738,
            41.87194
        ],
        "牙买加": [
            -77.297508,
            18.109581
        ],
        "约旦": [
            36.238414,
            30.585164
        ],
        "日本": [
            138.252924,
            36.204824
        ],
        "哈萨克斯坦": [
            66.923684,
            48.019573
        ],
        "肯尼亚": [
            37.906193,
            -0.023559
        ],
        "吉尔吉斯斯坦": [
            74.766098,
            41.20438
        ],
        "柬埔寨": [
            104.990963,
            12.565679
        ],
        "韩国": [
            127.766922,
            35.907757
        ],
        "科索沃": [
            20.902977,
            42.6026359
        ],
        "科威特": [
            47.481766,
            29.31166
        ],
        "老挝": [
            102.495496,
            19.85627
        ],
        "黎巴嫩": [
            35.862285,
            33.854721
        ],
        "利比里亚": [
            -9.429499000000002,
            6.428055
        ],
        "利比亚": [
            17.228331,
            26.3351
        ],
        "斯里兰卡": [
            80.77179699999999,
            7.873053999999999
        ],
        "莱索托": [
            28.233608,
            -29.609988
        ],
        "立陶宛": [
            23.881275,
            55.169438
        ],
        "卢森堡": [
            6.129582999999999,
            49.815273
        ],
        "拉脱维亚": [
            24.603189,
            56.879635
        ],
        "摩洛哥": [
            -7.092619999999999,
            31.791702
        ],
        "摩尔多瓦": [
            28.369885,
            47.411631
        ],
        "马达加斯加": [
            46.869107,
            -18.766947
        ],
        "墨西哥": [
            -102.552784,
            23.634501
        ],
        "马其顿": [
            21.745275,
            41.608635
        ],
        "马里": [
            -3.996166,
            17.570692
        ],
        "缅甸": [
            95.956223,
            21.913965
        ],
        "黑山": [
            19.37439,
            42.708678
        ],
        "蒙古": [
            103.846656,
            46.862496
        ],
        "莫桑比克": [
            35.529562,
            -18.665695
        ],
        "毛里塔尼亚": [
            -10.940835,
            21.00789
        ],
        "马拉维": [
            34.301525,
            -13.254308
        ],
        "马来西亚": [
            101.975766,
            4.210484
        ],
        "纳米比亚": [
            18.49041,
            -22.95764
        ],
        "新喀里多尼亚": [
            165.618042,
            -20.904305
        ],
        "尼日尔": [
            8.081666,
            17.607789
        ],
        "尼日利亚": [
            8.675277,
            9.081999
        ],
        "尼加拉瓜": [
            -85.207229,
            12.865416
        ],
        "荷兰": [
            5.291265999999999,
            52.132633
        ],
        "挪威": [
            8.468945999999999,
            60.47202399999999
        ],
        "尼泊尔": [
            84.12400799999999,
            28.394857
        ],
        "新西兰": [
            174.885971,
            -40.900557
        ],
        "阿曼": [
            55.923255,
            21.512583
        ],
        "巴基斯坦": [
            69.34511599999999,
            30.375321
        ],
        "巴拿马": [
            -80.782127,
            8.537981
        ],
        "秘鲁": [
            -75.015152,
            -9.189967
        ],
        "菲律宾": [
            121.774017,
            12.879721
        ],
        "巴布亚新几内亚": [
            143.95555,
            -6.314992999999999
        ],
        "波兰": [
            19.145136,
            51.919438
        ],
        "波多黎各": [
            -66.590149,
            18.220833
        ],
        "北朝鲜": [
            127.510093,
            40.339852
        ],
        "葡萄牙": [
            -8.224454,
            39.39987199999999
        ],
        "巴拉圭": [
            -58.443832,
            -23.442503
        ],
        "卡塔尔": [
            51.183884,
            25.354826
        ],
        "罗马尼亚": [
            24.96676,
            45.943161
        ],
        "俄罗斯": [
            105.318756,
            61.52401
        ],
        "卢旺达": [
            29.873888,
            -1.940278
        ],
        "西撒哈拉": [
            -12.885834,
            24.215527
        ],
        "沙特阿拉伯": [
            45.079162,
            23.885942
        ],
        "苏丹": [
            30.217636,
            12.862807
        ],
        "南苏丹": [
            31.3069788,
            6.876991899999999
        ],
        "塞内加尔": [
            -14.452362,
            14.497401
        ],
        "所罗门群岛": [
            160.156194,
            -9.64571
        ],
        "塞拉利昂": [
            -11.779889,
            8.460555
        ],
        "萨尔瓦多": [
            -88.89653,
            13.794185
        ],
        "索马里兰": [
            46.8252838,
            9.411743399999999
        ],
        "索马里": [
            46.199616,
            5.152149
        ],
        "塞尔维亚共和国": [
            21.005859,
            44.016521
        ],
        "苏里南": [
            -56.027783,
            3.919305
        ],
        "斯洛伐克": [
            19.699024,
            48.669026
        ],
        "斯洛文尼亚": [
            14.995463,
            46.151241
        ],
        "瑞典": [
            18.643501,
            60.12816100000001
        ],
        "斯威士兰": [
            31.465866,
            -26.522503
        ],
        "叙利亚": [
            38.996815,
            34.80207499999999
        ],
        "乍得": [
            18.732207,
            15.454166
        ],
        "多哥": [
            0.824782,
            8.619543
        ],
        "泰国": [
            100.992541,
            15.870032
        ],
        "塔吉克斯坦": [
            71.276093,
            38.861034
        ],
        "土库曼斯坦": [
            59.556278,
            38.969719
        ],
        "东帝汶": [
            125.727539,
            -8.874217
        ],
        "特里尼达和多巴哥": [
            -61.222503,
            10.691803
        ],
        "突尼斯": [
            9.537499,
            33.886917
        ],
        "土耳其": [
            35.243322,
            38.963745
        ],
        "坦桑尼亚联合共和国": [
            34.888822,
            -6.369028
        ],
        "乌干达": [
            32.290275,
            1.373333
        ],
        "乌克兰": [
            31.16558,
            48.379433
        ],
        "乌拉圭": [
            -55.765835,
            -32.522779
        ],
        "美国": [
            -95.712891,
            37.09024
        ],
        "乌兹别克斯坦": [
            64.585262,
            41.377491
        ],
        "委内瑞拉": [
            -66.58973,
            6.42375
        ],
        "越南": [
            108.277199,
            14.058324
        ],
        "瓦努阿图": [
            166.959158,
            -15.376706
        ],
        "西岸": [
            35.3027226,
            31.9465703
        ],
        "也门": [
            48.516388,
            15.552727
        ],
        "南非": [
            22.937506,
            -30.559482
        ],
        "赞比亚": [
            27.849332,
            -13.133897
        ],
        "津巴布韦": [
            29.154857,
            -19.015438
        ],
        "Afghanistan":
            [67.709953, 33.93911],
        "Angola":
            [
                17.873887,
                -11.202692
            ],
        "Albania":
            [
                20.168331,
                41.153332
            ],
        "UnitedArabEmirates":
            [
                53.847818,
                23.424076
            ],
        "Argentina":
            [
                -63.61667199999999,
                -38.416097
            ],
        "Armenia":
            [
                45.038189,
                40.069099
            ],
        "FrenchSouthern and AntarcticLands":
            [
                69.348557,
                -49.280366
            ],
        "Australia":
            [
                133.775136,
                -25.274398
            ],
        "Austria":
            [
                14.550072,
                47.516231
            ],
        "Azerbaijan":
            [
                47.576927,
                40.143105
            ],
        "Burundi":
            [
                29.918886,
                -3.373056
            ],
        "Belgium":
            [
                4.469936,
                50.503887
            ],
        "Benin":
            [
                2.315834,
                9.30769
            ],
        "BurkinaFaso":
            [
                -1.561593,
                12.238333
            ],
        "Bangladesh":
            [
                90.356331,
                23.684994
            ],
        "Bulgaria":
            [
                25.48583,
                42.733883
            ],
        "TheBahamas":
            [
                -77.39627999999999,
                25.03428
            ],
        "Bosnia andHerzegovina":
            [
                17.679076,
                43.915886
            ],
        "Belarus":
            [
                27.953389,
                53.709807
            ],
        "Belize":
            [
                -88.49765,
                17.189877
            ],
        "Bermuda":
            [
                -64.7505,
                32.3078
            ],
        "Bolivia":
            [
                -63.58865299999999,
                -16.290154
            ],
        "Brazil":
            [
                -51.92528,
                -14.235004
            ],
        "Brunei":
            [
                114.727669,
                4.535277
            ],
        "Bhutan":
            [
                90.433601,
                27.514162
            ],
        "Botswana":
            [
                24.684866,
                -22.328474
            ],
        "CentralAfricanRepublic":
            [
                20.939444,
                6.611110999999999
            ],
        "Canada":
            [
                -106.346771,
                56.130366
            ],
        "Switzerland":
            [
                8.227511999999999,
                46.818188
            ],
        "Chile":
            [
                -71.542969,
                -35.675147
            ],
        "China":
            [
                104.195397,
                35.86166
            ],
        "IvoryCoast":
            [
                -5.547079999999999,
                7.539988999999999
            ],
        "Cameroon":
            [
                12.354722,
                7.369721999999999
            ],
        "DemocraticRepublic of the Congo":
            [
                21.758664,
                -4.038333
            ],
        "Republic of theCongo":
            [
                15.827659,
                -0.228021
            ],
        "Colombia":
            [
                -74.297333,
                4.570868
            ],
        "CostaRica":
            [
                -83.753428,
                9.748916999999999
            ],
        "Cuba":
            [
                -77.781167,
                21.521757
            ],
        "NorthernCyprus":
            [
                33.429859,
                35.126413
            ],
        "Cyprus":
            [
                33.429859,
                35.126413
            ],
        "CzechRepublic":
            [
                15.472962,
                49.81749199999999
            ],
        "Germany":
            [
                10.451526,
                51.165691
            ],
        "Djibouti":
            [
                42.590275,
                11.825138
            ],
        "Denmark":
            [
                9.501785,
                56.26392
            ],
        "DominicanRepublic":
            [
                -70.162651,
                18.735693
            ],
        "Algeria":
            [
                1.659626,
                28.033886
            ],
        "Ecuador":
            [
                -78.18340599999999,
                -1.831239
            ],
        "Egypt":
            [
                30.802498,
                26.820553
            ],
        "Eritrea":
            [
                39.782334,
                15.179384
            ],
        "Spain":
            [
                -3.74922,
                40.46366700000001
            ],
        "Estonia":
            [
                25.013607,
                58.595272
            ],
        "Ethiopia":
            [
                40.489673,
                9.145000000000001
            ],
        "Finland":
            [
                25.748151,
                61.92410999999999
            ],
        "Fiji":
            [
                178.065032,
                -17.713371
            ],
        "FalklandIslands":
            [
                -59.523613,
                -51.796253
            ],
        "France":
            [
                2.213749,
                46.227638
            ],
        "Gabon":
            [
                11.609444,
                -0.803689
            ],
        "UnitedKingdom":
            [
                -3.435973,
                55.378051
            ],
        "Georgia":
            [
                -82.9000751,
                32.1656221
            ],
        "Ghana":
            [
                -1.023194,
                7.946527
            ],
        "Guinea":
            [
                -9.696645,
                9.945587
            ],
        "Gambia":
            [
                -15.310139,
                13.443182
            ],
        "GuineaBissau":
            [
                -15.180413,
                11.803749
            ],
        "EquatorialGuinea":
            [
                10.267895,
                1.650801
            ],
        "Greece":
            [
                21.824312,
                39.074208
            ],
        "Greenland":
            [
                -42.604303,
                71.706936
            ],
        "Guatemala":
            [
                -90.23075899999999,
                15.783471
            ],
        "FrenchGuiana":
            [
                -53.125782,
                3.933889
            ],
        "Guyana":
            [
                -58.93018,
                4.860416
            ],
        "Honduras":
            [
                -86.241905,
                15.199999
            ],
        "Croatia":
            [
                15.2,
                45.1
            ],
        "Haiti":
            [
                -72.285215,
                18.971187
            ],
        "Hungary":
            [
                19.503304,
                47.162494
            ],
        "Indonesia":
            [
                113.921327,
                -0.789275
            ],
        "India":
            [
                78.96288,
                20.593684
            ],
        "Ireland":
            [
                -8.24389,
                53.41291
            ],
        "Iran":
            [
                53.688046,
                32.427908
            ],
        "Iraq":
            [
                43.679291,
                33.223191
            ],
        "Iceland":
            [
                -19.020835,
                64.963051
            ],
        "Israel":
            [
                34.851612,
                31.046051
            ],
        "Italy":
            [
                12.56738,
                41.87194
            ],
        "Jamaica":
            [
                -77.297508,
                18.109581
            ],
        "Jordan":
            [
                36.238414,
                30.585164
            ],
        "Japan":
            [
                138.252924,
                36.204824
            ],
        "Kazakhstan":
            [
                66.923684,
                48.019573
            ],
        "Kenya":
            [
                37.906193,
                -0.023559
            ],
        "Kyrgyzstan":
            [
                74.766098,
                41.20438
            ],
        "Cambodia":
            [
                104.990963,
                12.565679
            ],
        "SouthKorea":
            [
                127.766922,
                35.907757
            ],
        "Kosovo":
            [
                20.902977,
                42.6026359
            ],
        "Kuwait":
            [
                47.481766,
                29.31166
            ],
        "Laos":
            [
                102.495496,
                19.85627
            ],
        "Lebanon":
            [
                35.862285,
                33.854721
            ],
        "Liberia":
            [
                -9.429499000000002,
                6.428055
            ],
        "Libya":
            [
                17.228331,
                26.3351
            ],
        "SriLanka":
            [
                80.77179699999999,
                7.873053999999999
            ],
        "Lesotho":
            [
                28.233608,
                -29.609988
            ],
        "Lithuania":
            [
                23.881275,
                55.169438
            ],
        "Luxembourg":
            [
                6.129582999999999,
                49.815273
            ],
        "Latvia":
            [
                24.603189,
                56.879635
            ],
        "Morocco":
            [
                -7.092619999999999,
                31.791702
            ],
        "Moldova":
            [
                28.369885,
                47.411631
            ],
        "Madagascar":
            [
                46.869107,
                -18.766947
            ],
        "Mexico":
            [
                -102.552784,
                23.634501
            ],
        "Macedonia":
            [
                21.745275,
                41.608635
            ],
        "Mali":
            [
                -3.996166,
                17.570692
            ],
        "Myanmar":
            [
                95.956223,
                21.913965
            ],
        "Montenegro":
            [
                19.37439,
                42.708678
            ],
        "Mongolia":
            [
                103.846656,
                46.862496
            ],
        "Mozambique":
            [
                35.529562,
                -18.665695
            ],
        "Mauritania":
            [
                -10.940835,
                21.00789
            ],
        "Malawi":
            [
                34.301525,
                -13.254308
            ],
        "Malaysia":
            [
                101.975766,
                4.210484
            ],
        "Namibia":
            [
                18.49041,
                -22.95764
            ],
        "NewCaledonia":
            [
                165.618042,
                -20.904305
            ],
        "Niger":
            [
                8.081666,
                17.607789
            ],
        "Nigeria":
            [
                8.675277,
                9.081999
            ],
        "Nicaragua":
            [
                -85.207229,
                12.865416
            ],
        "Netherlands":
            [
                5.291265999999999,
                52.132633
            ],
        "Norway":
            [
                8.468945999999999,
                60.47202399999999
            ],
        "Nepal":
            [
                84.12400799999999,
                28.394857
            ],
        "NewZealand":
            [
                174.885971,
                -40.900557
            ],
        "Oman":
            [
                55.923255,
                21.512583
            ],
        "Pakistan":
            [
                69.34511599999999,
                30.375321
            ],
        "Panama":
            [
                -80.782127,
                8.537981
            ],
        "Peru":
            [
                -75.015152,
                -9.189967
            ],
        "Philippines":
            [
                121.774017,
                12.879721
            ],
        "PapuaNewGuinea":
            [
                143.95555,
                -6.314992999999999
            ],
        "Poland":
            [
                19.145136,
                51.919438
            ],
        "PuertoRico":
            [
                -66.590149,
                18.220833
            ],
        "NorthKorea":
            [
                127.510093,
                40.339852
            ],
        "Portugal":
            [
                -8.224454,
                39.39987199999999
            ],
        "Paraguay":
            [
                -58.443832,
                -23.442503
            ],
        "Qatar":
            [
                51.183884,
                25.354826
            ],
        "Romania":
            [
                24.96676,
                45.943161
            ],
        "Russia":
            [
                105.318756,
                61.52401
            ],
        "Rwanda":
            [
                29.873888,
                -1.940278
            ],
        "WesternSahara":
            [
                -12.885834,
                24.215527
            ],
        "SaudiArabia":
            [
                45.079162,
                23.885942
            ],
        "Sudan":
            [
                30.217636,
                12.862807
            ],
        "SouthSudan":
            [
                31.3069788,
                6.876991899999999
            ],
        "Senegal":
            [
                -14.452362,
                14.497401
            ],
        "SolomonIslands":
            [
                160.156194,
                -9.64571
            ],
        "SierraLeone":
            [
                -11.779889,
                8.460555
            ],
        "ElSalvador":
            [
                -88.89653,
                13.794185
            ],
        "Somaliland":
            [
                46.8252838,
                9.411743399999999
            ],
        "Somalia":
            [
                46.199616,
                5.152149
            ],
        "RepublicofSerbia":
            [
                21.005859,
                44.016521
            ],
        "Suriname":
            [
                -56.027783,
                3.919305
            ],
        "Slovakia":
            [
                19.699024,
                48.669026
            ],
        "Slovenia":
            [
                14.995463,
                46.151241
            ],
        "Sweden":
            [
                18.643501,
                60.12816100000001
            ],
        "Swaziland":
            [
                31.465866,
                -26.522503
            ],
        "Syria":
            [
                38.996815,
                34.80207499999999
            ],
        "Chad":
            [
                18.732207,
                15.454166
            ],
        "Togo":
            [
                0.824782,
                8.619543
            ],
        "Thailand":
            [
                100.992541,
                15.870032
            ],
        "Tajikistan":
            [
                71.276093,
                38.861034
            ],
        "Turkmenistan":
            [
                59.556278,
                38.969719
            ],
        "EastTimor":
            [
                125.727539,
                -8.874217
            ],
        "Trinidad andTobago":
            [
                -61.222503,
                10.691803
            ],
        "Tunisia":
            [
                9.537499,
                33.886917
            ],
        "Turkey":
            [
                35.243322,
                38.963745
            ],
        "UnitedRepublic ofTanzania":
            [
                34.888822,
                -6.369028
            ],
        "Uganda":
            [
                32.290275,
                1.373333
            ],
        "Ukraine":
            [
                31.16558,
                48.379433
            ],
        "Uruguay":
            [
                -55.765835,
                -32.522779
            ],
        "UnitedStates ofAmerica":
            [
                -95.712891,
                37.09024
            ],
        "Uzbekistan":
            [
                64.585262,
                41.377491
            ],
        "Venezuela":
            [
                -66.58973,
                6.42375
            ],
        "Vietnam":
            [
                108.277199,
                14.058324
            ],
        "Vanuatu":
            [
                166.959158,
                -15.376706
            ],
        "WestBank":
            [
                35.3027226,
                31.9465703
            ],
        "Yemen":
            [
                48.516388,
                15.552727
            ],
        "SouthAfrica":
            [
                22.937506,
                -30.559482
            ],
        "Zambia": [27.849332, -13.133897],
        "Zimbabwe": [29.154857, -19.015438]
    }
