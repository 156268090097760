<template>
    <div class="news">
      <div class="title">大宗商品价格涨跌</div>
        <el-table
            class="table" style="height: 1000px"
                :data="IeList"
                ref="IETable"
               >
            <el-table-column
                    prop="ProductName"
                    label="商品"
                    width="25%"
            >
            </el-table-column>
            <el-table-column
                    prop="Price"
                    label="价格(USD)"
                    width="25%"
            >
            </el-table-column>
            <el-table-column
                    prop="ToLastDayDouble"
                    label="涨跌额"
                    width="25%">
                <template v-slot="scope">
                  <img src="@/assets/leftIcon/up.png" alt="">
                    {{ scope.row.ToLastDayDouble }}%
                </template>
            </el-table-column>
            <el-table-column
                    prop="RiseandFallDouble"
                    label="涨跌幅"
                    width="25%">
                <template v-slot="scope">
                  <img src="@/assets/leftIcon/down.png" alt="">
                    {{ scope.row.RiseandFallDouble }}%
                </template>
            </el-table-column>
        </el-table>
      <border-line></border-line>
    </div>
</template>

<script>
import BorderLine from "@/components/BorderLine/BorderLine.vue";
export default {
  name: "RiseandFall",
  components: {BorderLine},
  props: {
    materialNews: {
      type: Array,
      default: () => ([])
    },
    IEList: {
      type: Array,
      default: () => ([])
    },
    upDownList: {
      type: Array,
      default: () => ([])
    }
  },
  data() {
    return {
      IeList: [],
      timer: null,
      timer1: null,
    }
  },
  mounted() {
    this.startScroll();
  },
  destroyed() {
    this.stopScroll();
  },
  created() {
    this.getData()
  },

  methods: {
    startScroll() {
      this.stopScroll(); // 确保只有一个定时器在运行
      this.timer = setInterval(() => {
        const tableEl = this.$refs.IETable.$el.querySelector(".el-table__body-wrapper");
        if (tableEl) {
          const scrollTop = tableEl.scrollTop;
          const scrollHeight = tableEl.scrollHeight;
          const clientHeight = tableEl.clientHeight;
          if (scrollTop + clientHeight >= scrollHeight) {
            tableEl.scrollTop = 0; // 滚动到顶部
          } else {
            tableEl.scrollTop += 1; // 每次滚动的距离
          }
        }
      }, 50); // 滚动的间隔时间
    },
    stopScroll() {
      if (this.timer) {
        clearInterval(this.timer); // 停止定时器
        this.timer = null;
      }
    },
      getData(){
        this.api.getGoodsPriceFluctuationsData().then(res => {
              this.IeList = res.data
            }
        )
      },
        },
}
</script>

<style scoped>
.table .el-table__body-wrapper {
  overflow: auto;
}
.customer-table{
  width: 100%;
  height: 90% !important;
}
.news {
  width: 400px;
  height: 364px;
  position: relative;
}
.title {
  width: 100%;
  padding: 10px 0;
  background: linear-gradient(90deg, rgba(5, 63, 152, 0) 0%, #053F98 47%, rgba(5, 63, 152, 0) 100%);
  font-size: 16px;
  color: #00CAF0;
}
/deep/ .el-table__body-wrapper {
  height: 270px!important;
}
/deep/ .el-table {
    .cell {
        line-height: 14px;
    }
}

/deep/ .el-table__cell {
    padding: 12px 0 !important;
    text-align: center;
    color: #121720;
    font-size: 14px;
}

/deep/ .cell {
    white-space: nowrap !important;
    color: #fff;
}

/deep/ .el-table::before {
    height: 0;
}

/deep/ .el-table th.el-table__cell {
    background: none;
}

/deep/ .el-table th.el-table__cell.is-leaf {
    border: none;
}

/deep/ .el-table {
    background: none;
}
/deep/.el-table--enable-row-hover .el-table__body tr:hover>td.el-table__cell{
  background-color: rgba(255,255,255,.2);
}
/deep/ .el-table tr {
    background: none;
}

/deep/ .el-table__body-wrapper {
  height: 100px;
    scrollbar-width: none;
}
/deep/ .el-table__body-wrapper::-webkit-scrollbar {
    width: 0;
    display: none;
}

/deep/ .el-table th.gutter {
    width: 0 !important;
    height: 0;
    display: none;
}
/deep/.el-table--scrollable-y .el-table__body-wrapper{
  height: 250px;
}
/deep/ .el-table colgroup col[name='gutter'] {
    display: none;
    width: 0;
}
/deep/.el-table__body-wrapper is-scrolling-none{
  height: 200px;
}
/deep/ .el-table__body {
    width: 100% !important;
}

/deep/ .el-table__header {
    width: 100% !important;
}
</style>
