<template>
  <div class="card">
    <div class="title">中非地区交易占比</div>
    <div>
        <el-tabs class="swicon" v-model="F_AreaType" @tab-click="handleClick">
          <el-tab-pane v-for="item in areaList" :key="item.value+''" :label="item.label"  class="select-item"></el-tab-pane>
        </el-tabs>
    </div>
    <div  class="swicenter" ref="mainData" :style="{width: '100%', height: '220px'}"/>
    <border-line></border-line>
  </div>
</template>
<script>
import * as echarts from 'echarts'
import BorderLine from "@/components/BorderLine/BorderLine.vue";
export default {
  name: 'CommonFanning',
  components: {BorderLine},
  data(){
    return {
      aData: [],
      F_AreaType:'0',
      areaList:[
        {
          label: '中国地区',
          value: 1,
        },
        {
          label: '非洲地区',
          value: 2,
        }
      ],
      F_AreaTypedata:'1'
    }
  },
  mounted() {
    this.init()
  },
  created() {
    this.getData()
  },
  methods: {
    async getData() {
      const data = {
        F_AreaType:Number(this.F_AreaTypedata)
      }
      console.log(data)
      let res = await this.api.getOrderRegionTransaction(data)
      this.aData = res.data
      console.log(this.aData,'123')
      this.init()
    },
    handleClick(tab) {
      this.F_AreaTypedata=( Number(tab.index) + 1);
      this.getData()
    },
     init() {
      const myChart = echarts.init(this.$refs.mainData)
      const option = {
        legend: {
          top: 'bottom'
        },
        toolbox: {
          show: false,
          feature: {
            mark: { show: false },
            dataView: { show: false, readOnly: false },
            restore: { show: false },
            saveAsImage: { show: false }
          }
        },
        // series: [
        //   {
        //     name: 'Nightingale Chart',
        //     type: 'pie',
        //     radius: [30, 80],
        //     center: ['50%', '50%'],
        //     roseType: 'area',
        //     itemStyle: {
        //       borderRadius: 8
        //     },
        //     data: this.aData,
        //   }
        // ]
        // series: [
        //   {
        //     name: 'Nightingale Chart',
        //     type: 'pie',
        //     radius: [20, 70],
        //     center: ['50%', '50%'],
        //     roseType: 'area',
        //     itemStyle: {
        //       borderRadius: 1
        //     },
        //     data:this.aData.map(item=>{
        //       return {
        //         name:item.name,
        //         value:item.value
        //       }
        //     }),
        //     label: {
        //       normal: {
        //         position: 'inside',
        //         show: true,
        //         textStyle: {
        //           color: '#ffffff',
        //           fontSize: '12px',
        //         },
        //         formatter: (d) => {
        //           return d.percent + '%'
        //         }
        //       }
        //     }
        //   }
        // ]
        series: [
          {
            type: 'pie',
            roseType: 'area',
            radius: ['20%', '70%'],
            startAngle: 125,
            data: this.aData,
            labelLine: {
              show: true,
              position: 'outside',
              length: 10,
              length2: 40
            },
            emphasis: {
              itemStyle: {
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            },
            label: {
              normal: {
                position: 'outside',
                show: true,
                rotate: 0,
                color: '#ffffff',
                fontSize: '12px',
                formatter: (d) => {
                  return d.name + '（' +  d.value  + '%' +'）'
                }
              }
            }
          },
          {
            type: 'pie',
            radius: ['20%', '70%'],
            data: this.aData,
            startAngle: 125,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            },
            label: {
              normal: {
                position: 'inside',
                show: true,
                textStyle: {
                  color: '#ffffff',
                  fontSize: '12px',
                },
                formatter: (d) => {
                  console.log(d,'123')
                  // return d.percent + '%'
                }
              }
            }
          }
        ]
      };
      option && myChart.setOption(option);
    }
  }
}
</script>

<style scoped>
.card {
  width: 400px;
  height: 364px;
  position: relative;
}

.title {
  width: 100%;
  padding: 10px 0;
  background: linear-gradient(90deg, rgba(5, 63, 152, 0) 0%, #053F98 47%, rgba(5, 63, 152, 0) 100%);
  font-size: 16px;
  color: #00CAF0;
}

.swicon{
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
.swicenter{
 margin: 0 auto;
}
/deed/.el-tabs__nav-wrap::after {
  height: 0px;
  background:none;
}
::v-deep .el-tabs__content {
  overflow: visible;
}
::v-deep .el-tabs__item {
  color: white;
}
::v-deep .el-tabs__item.is-active {
  color: #15cbf3;
}
::v-deep .el-icon-arrow-left {
  color: white;
}
::v-deep .el-icon-arrow-right {
  color: white;
}
::v-deep .el-tabs__nav-wrap::after {
  height: 0;
}
::v-deep .el-tabs__active-bar {
  background-color: #15cbf3;
}
</style>
